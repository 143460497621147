import { poppins } from "@/styles/font";
import { Button, Divider, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillFacebook,
} from "react-icons/ai";

const Welcome = () => {

  const router = useRouter()
  return (
    <Flex
      rounded={"md"}
      boxShadow={"md"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      px={4}
      h={'full'}
      backgroundColor={'white'}
    >
      <Text
        className={poppins.className}
        color={"martlinegray.600"}
        fontSize={"md"}
        fontWeight={'medium'}
      >
        Welcome to Martlines
      </Text>

      <Divider my={5} backgroundColor={"martlinegray.300"} h={"1px"} />

      <HStack>
        <Button
          variant={'solid'}
          className={poppins.className}
          onClick={() => router.push('/signin')}
        >
          Register
        </Button>
        <Button
          variant={'outline'}
          className={poppins.className}
          onClick={() => router.push('/signin')}
        >
          Sign in
        </Button>
      </HStack>

      <Divider my={8} maxW={'60%'} orientation={'horizontal'} />

      <HStack color={"martlinegray.600"} spacing={5}>
        <Link href={'https://www.facebook.com/martlines.ng'}>
          <Icon boxSize={6} as={AiFillFacebook} aria-label={"Facebook"} _hover={{ cursor: 'pointer', color: 'martlineorange.500' }} />
        </Link>
        <Link href={'https://twitter.com/MartlinesNg'}>
          <Icon boxSize={6} as={AiOutlineTwitter} aria-label={"Twitter"} _hover={{ cursor: 'pointer', color: 'martlineorange.500' }} />
        </Link>
        <Link href={'https://www.instagram.com/tony_martlines'}>
          <Icon boxSize={6} as={AiFillInstagram} aria-label={"Instagram"} _hover={{ cursor: 'pointer', color: 'martlineorange.500' }} />
        </Link>
      </HStack>
    </Flex>
  );
};

export default Welcome;
